/**
 * Create Media Query mixins
 */
$screen-sm-max: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-sm-max}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

$color-dark-blue: #003;
$color-light-blue: #4abdf7;
$color-orange: #ffbc00;

html {
  font-size: 14px;

  @include md {
    font-size: 14pt;
  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

h2.sidebar {
  background-color: $color-dark-blue;
  color: white;
  padding: 5px 10px;
  margin: 20px 0;
}

footer {
  margin-top: 3em;
  background-color: $color-light-blue;
  color: $color-dark-blue;
  text-align: center;
}

.footer-text {
  font-size: 0.8em;
  padding: 1.5em 0;
  width: 100%;
}

.top-logo {
  margin: 20px auto;
  width: 100%;
  height: auto;

  @include lg{
    height: 100px;
    width: auto;
  }
}

.nelson-nav {
  background: $color-dark-blue !important;
  margin-bottom: 20px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1.0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.nav-link {
  padding: 12px 20px;
  color: white;
  &:hover {
    color: white;
    background-color: $color-light-blue;
  }
  &.active {
    color: white;
    background-color: $color-light-blue;
  }
}

a.dropdown-item:hover {
  color: white;
  background-color: $color-light-blue;
}

a.dropdown-item.active {
  color: white;
  background-color: $color-light-blue;
}

.carousel-inner {
  max-width: 700px;
}

.nelson-button {
  text-decoration: none;
  background-color: $color-orange;
  color: black;
  padding: 10px 30px;
  box-shadow: 2px 2px 4px darkgrey;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

img.inline-left {
  float: left;
  width: 50%;
  max-width: 360px;
  //@include md {
  //  width: 25%;
  //  max-width: 225px;
  //};
  @media (min-width: 800px) {
    width: 25%;
    max-width: 225px;
  }
  min-width: 180px;
  margin: 5px 20px 5px 0;
  box-shadow: 2px 2px 5px grey;
}

img.inline-right {
  float: right;
  width: 50%;
  max-width: 360px;
  //@include md{
  //  width: 50%;
  //  max-width: 360px;
  //}
  @media (min-width: 800px) {
    width: 25%;
    max-width: 225px;
  }
  min-width: 180px;
  margin: 5px 0 5px 20px;
  box-shadow: 2px 2px 5px grey;
}